<template>
  <section :class="slideClasses" v-bind="slideData">
    <div :class="outerClasses">
      <AbsoluteBlock
        v-for="(block, index) in absoluteBlocks"
        :key="block.id"
        :block="block"
        :is-thumbnail="isThumbnail"
      />
      <div class="flex w-full h-full items-center text-left">
        <div :class="innerClasses" data-scrollable-container>
          <RelativeBlock
            v-for="(block, index) in relativeBlocks"
            :key="block.id"
            :block="block"
            :is-thumbnail="isThumbnail"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import {
  DIRECTION_MAPPINGS,
  ALIGN_MAPPINGS,
  JUSTIFY_MAPPINGS,
  PADDING_MAPPINGS
} from '@/features/slides_editor/config/constants/layout'

import AbsoluteBlock from './blocks/AbsoluteBlock.vue'
import RelativeBlock from './blocks/RelativeBlock.vue'

const props = defineProps({
  slide: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  }
})

const slideClasses = computed(() => [
  'h-full',
  'w-full',
  'relative',
  'actual-slide',
  { 'outline': props.slide.bordered },
  { 'outline-gray-200': props.slide.bordered }
]);

const slideData = computed(() => ({
  'data-background-color': props.slide.layout.background_color
}));

const outerClasses = computed(() => [
  'h-full',
  'w-full',
  'relative',
  { 'overflow-hidden': props.slide.allow_overflow }
]);

const innerClasses = computed(() => [
  'h-full',
  'w-full',
  'flex',
  'overflow-y-auto',
  'scroll-auto',
  DIRECTION_MAPPINGS[props.slide.layout.direction] || '',
  ALIGN_MAPPINGS[props.slide.layout.align] || '',
  JUSTIFY_MAPPINGS[props.slide.layout.justify] || '',
  PADDING_MAPPINGS.top[props.slide.layout.padding_top] || '',
  PADDING_MAPPINGS.right[props.slide.layout.padding_right] || '',
  PADDING_MAPPINGS.bottom[props.slide.layout.padding_bottom] || '',
  PADDING_MAPPINGS.left[props.slide.layout.padding_left] || ''
]);

const absoluteBlocks = computed(() => props.slide.blocks.filter(block => block.absolute));
const relativeBlocks = computed(() => props.slide.blocks.filter(block => !block.absolute));
</script>
