import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js/auto"
import 'chartjs-adapter-date-fns'

export default class extends Controller {
  static values = {
    data: Array,
    options: Object
  }

  connect() {
    this.initializeChart()
  }

  initializeChart() {
    const datasets = this.buildDatasets()
    const timeScale = this.optionsValue.time_scale
    const yScales = this.buildYScales()

    new Chart(this.element, {
      type: 'line',
      data: {
        datasets
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        scales: {
          x: {
            type: 'time',
            time: { unit: timeScale }
          },
          ...yScales
        }
      }
    })
  }

  buildDatasets() {
    return this.dataValue.map((series, index) => ({
      label: series.name,
      data: series.data.map(([date, value]) => ({
        x: new Date(date),
        y: value
      })),
      borderColor: series.color,
      backgroundColor: series.color, 
      tension: 0.4,
      yAxisID: index === 0 ? "y" : `y${index}`
    }))
  }

  buildYScales() {
    const scales = {}

    this.dataValue.forEach((series, index) => {
      const axisId = index === 0 ? "y" : `y${index}`
      scales[axisId] = {
        position: index === 0 ? 'left' : 'right',
        title: {
          display: true,
          text: series.name
        },
        grid: {
          drawOnChartArea: index === 0 ? true : false
        }
      }
    })

    return scales
  }
}