import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="html-attributes"
export default class extends Controller {
  static values = {
    name: String,
    value: String,
    element: String
  }

  set() {
    const elements = this.hasElementValue ? document.querySelectorAll(this.elementValue) : [this.element]
    elements.forEach(element => element.setAttribute(this.nameValue, this.valueValue))
  }

  remove() {
    const elements = this.hasElementValue ? document.querySelectorAll(this.elementValue) : [this.element]
    elements.forEach(element => element.removeAttribute(this.nameValue))
  }
}
