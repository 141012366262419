import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.fuzzyStart = null
    this.fuzzyThreshold = 5 * 60 * 1000 // 5 minutes in milliseconds
    this.lastActivity = Date.now()
    this.activeTime = 0

    this.startTimer()
    this.initializeVisibilityTracking()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.visible) {
        const now = Date.now()
        const delta = now - this.lastActivity

        this.activeTime += delta
        this.lastActivity = now

        // Check if a full minute of active time has passed
        if (this.activeTime >= 60000) {
          this.track()

          // Reset active time
          this.activeTime = 0
        }
      } else {
        this.lastActivity = Date.now()
      }
    }, 1000)
  }

  initializeVisibilityTracking() {
    document.addEventListener("visibilitychange", () => {
      const state = document.visibilityState

      if (state === "visible") {
        this.lastActivity = Date.now()

        if (this.fuzzyStart && (Date.now() - this.fuzzyStart <= this.fuzzyThreshold)) {
          const reclaimed = Date.now() - this.fuzzyStart
          this.activeTime += reclaimed
          this.fuzzyStart = null
        }
      } else if (state === "hidden") {
        this.fuzzyStart = Date.now()
      }
    })

    window.addEventListener("blur", () => {
      this.focused = false
    })

    window.addEventListener("focus", () => {
      this.focused = true
      this.lastActivity = Date.now()
    })
  }

  track() {
    post(this.urlValue, { body: JSON.stringify({ active_time: Math.round(this.activeTime / 1000) }) })
  }

  get visible() {
    return document.visibilityState === "visible" && this.focused
  }
}
