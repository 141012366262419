import { Controller } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"
import debounce from "debounce"
import { TOOLBAR_ITEMS } from "@/helpers/constants/toolbar_items"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.editable = this.contentTarget.dataset.editable === "true"
    this.initializeDebouncedSubmit()

    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      content: this.contentTarget.dataset.content,
      element: this.contentTarget,
      editable: this.editable,
      onUpdate: () => {
        this.createHiddenInput()
        this.updateHiddenInput()
        this.toggleEmptyState()
        this.debouncedSubmit()
      },
      onSelectionUpdate: () => {
        this.updateToolbar()
      }
    })

    this.toggleEmptyState()
  }

  disconnect() {
    this.editor.destroy()
  }

  createHiddenInput() {
    if (!this.contentTarget.querySelector("input")) {
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = this.contentTarget.dataset.field
      this.contentTarget.appendChild(input)
    }
  }

  updateHiddenInput() {
    this.contentTarget.querySelector("input").value = this.editor.getHTML()
  }

  toggleEmptyState() {
    this.contentTarget.classList.toggle("is-empty", this.editor.isEmpty)
  }

  initializeDebouncedSubmit() {
    const form = this.element.closest("form")

    if (form && this.editable) {
      this.debouncedSubmit = debounce(() => form.requestSubmit(), 300)
    } 
  }

  toggleToolbarItem(event) {
    const itemName = event.currentTarget.dataset.item
    const toolbarItem = TOOLBAR_ITEMS.find(item => item.iconName === itemName)

    if (toolbarItem) {
      toolbarItem.action(this.editor)
      if (!['undo', 'redo'].includes(itemName)) {
        event.currentTarget.classList.toggle("is-active")
      }
    }
  }

  updateToolbar() {
    const toolbarItems = this.element.querySelectorAll(".tiptap-toolbar button")
    toolbarItems.forEach(button => {
      const itemName = button.dataset.item
      const toolbarItem = TOOLBAR_ITEMS.find(item => item.iconName === itemName)

      if (toolbarItem) {
        button.classList.toggle("is-active", toolbarItem.isActive(this.editor))
      }
    })
  }
}
