import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hubspot-chat"
export default class extends Controller {
  static values = {
    email: String,
    token: String,
    url: String
  }

  connect() {
    document.addEventListener("turbo:load", this.reinitialize.bind(this))
    document.addEventListener("turbo:render", this.reinitialize.bind(this))

    if (!window.HubSpotConversations) {
      this.loadScript()
    } else {
      this.initializeWidget()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.reinitialize.bind(this))
    document.removeEventListener("turbo:render", this.reinitialize.bind(this))
  }

  reinitialize() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove()
      this.initializeWidget()
    }
  }

  loadScript() {
    this.script = document.createElement("script")
    this.script.src = this.urlValue
    this.script.onload = () => this.initializeWidget()
    this.script.onerror = (error) => {
      console.error("Failed to load HubSpot chat widget:", error)
    }
    document.head.appendChild(this.script)
  }

  initializeWidget() {
    window.hsConversationsSettings = {
      identificationEmail: this.emailValue,
      identificationToken: this.tokenValue,
      loadImmediately: true
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load()
    }
  }
}
