import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import TurboPower from 'turbo_power'
import "trix"
import "@rails/actiontext"
import "../libs/rich_text"
import "../controllers"
import "../turbo_mount"
import "chart.js"

ActiveStorage.start()

TurboPower.initialize(Turbo.StreamActions)

const confirmationDialog = (message, element) => {
  const dialog = document.getElementById("turbo_confirm")

  let {
    confirmTitle: titleText,
    confirmBody: bodyText,
    confirmBtnText,
    cancelBtnText,
    confirmVariant,
    hideConfirm
  } = element.dataset

  titleText = titleText || dialog.dataset.titleText
  bodyText = bodyText || dialog.dataset.bodyText
  confirmBtnText = confirmBtnText || dialog.dataset.confirmText
  cancelBtnText = cancelBtnText || dialog.dataset.cancelText
  confirmVariant = confirmVariant || dialog.dataset.variant

  dialog.querySelector('[data-title]').innerText = titleText
  dialog.querySelector('[data-body]').innerHTML = bodyText
  dialog.querySelector('[data-confirm]').innerText = confirmBtnText
  dialog.querySelector('[data-cancel]').innerText = cancelBtnText
  dialog.setAttribute('data-variant', confirmVariant)

  if (hideConfirm) {
    dialog.querySelector('[data-confirm]').classList.add('hidden')
  }

  dialog.classList.remove('hidden')

  dialog.showModal()
  dialog.focus()

  return new Promise((resolve, reject) => {
    dialog.addEventListener("close", () => {
      dialog.classList.add('hidden')

      document.querySelectorAll('[data-loading="true"]').forEach(el => {
        el.removeAttribute('data-loading')
      })

      resolve(dialog.returnValue === "confirm")
    }, { once: true })
  })
}

window.confirmationDialog = confirmationDialog

// custom confirm dialog
Turbo.setConfirmMethod(confirmationDialog)
